@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import "./assets/scss/main.scss";

html,
body {
    height: 100%;
    width: 100%;
    margin: 0;
}

.mat-spinner {
    height: 50px !important;
    width: 50px !important;

    svg {
        height: 50px !important;
        width: 50px !important;
    }
}

.swal2-title {
    font-weight: bold !important;
    padding: 0 !important;
}

.swal2-html-container {
    font-size: 15px !important;
    margin: 0 !important;
}

.swal2-styled.swal2-confirm {
    background-color: #437eb3 !important;
    box-shadow: none !important;
}

input.ng-invalid.ng-touched,
textarea.ng-invalid.ng-touched,
select.ng-invalid.ng-touched {
    border: 2px solid #ea3d3d;
}

.mat-form-field {
    .ng-invalid {
        border: none !important;
    }
}

.mat-form-field-wrapper {
    height: 0 !important;
}

.mat-form-field-appearance-outline .mat-form-field-infix {
    padding: 0.5em !important;
}

.toast-success {
    background-color: rgb(189, 227, 119) !important;
}

.toast-error {
    background-color: rgb(202, 41, 53) !important;
}
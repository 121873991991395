@use '@angular/material' as mat;

* {
    font-family: "Montserrat", sans-serif;
}

$custom-typography: mat.define-typography-config(
    $font-family: "Montserrat",
);

@include mat.all-component-typographies($custom-typography);
